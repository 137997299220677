import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LogRocket from 'logrocket';
/* --------------------------------------------------------------- */
import { withAuthUser } from "../Firebase/index.js";
/* --------------------------------------------------------------- */
//import Homepage from "../Homepage/Homepage.js";
//import SignIn from "../Account/SignIn/AccountSignin.js";
import ErrorBoundary from "../Error/ErrorBoundary.js";
import Header from "../Header/Header.js";
import Error404 from "../Error/Error404.js";
import { Loader, Dimmer, Container } from "semantic-ui-react";
import AccountAction from "../Account/Action/AccountAction.js";
import AccountSignin from "../Account/SignIn/AccountSignin.js";
import AccountRegistration from "../Account/Registration/AccountRegistration.js";
import AccountChangePW from "../Account/ChangePassword/ChangePassword.js";
import AccountForgotPW from "../Account/ForgotPassword/ForgotPassword.js";
import AccountResetPW from "../Account/ForgotPassword/ResetPassword.js";
import AccountProfile from "../Account/EditProfile/EditProfile.js";
import ErrorUnauthorized from "../Error/ErrorUnauthorized.js";
/* --------------------------------------------------------------- */
import * as ROUTES from "../constants/routes.js";
/* --------------------------------------------------------------- */
const Homepage = lazy(() => import("../Homepage/Homepage.js"));
const Events = lazy(() => import("../Events/Events.js"));
const EventCreate = lazy(() => import("../Events/Create/EventCreate.js"));
const AccountDashboard = lazy(() => import("../Dashboard/Dashboard.js"));
//const AccountAction = lazy(() => import("../Account/Action/AccountAction.js"));
//const AccountSignin = lazy(() => import("../Account/SignIn/AccountSignin.js"));
//const AccountRegistration = lazy(() => import("../Account/Registration/AccountRegistration.js"));
//const AccountChangePW = lazy(() => import("../Account/ChangePassword/ChangePassword.js"));

const INITIAL_STATE = {
  offCanvasVisible: false
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  

  LoadingScreen = () => (
    <Dimmer inverted active>
      <Loader size={"huge"}>Loading</Loader>
    </Dimmer>
  );

  CheckEnvironment = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      //console.log(this.props.firebase.auth.currentUser.uid + " " + this.props.firebase.auth.currentUser.email);
      return this.ApplicationView();
    } else {
      LogRocket.init('o8hknh/pointbox-production',{
        console: {
          shouldAggregateConsoleErrors: true,
        },
      });
      if(this.props.firebase.auth.currentUser && this.props.firebase.auth.currentUser.uid) {
        LogRocket.identify(this.props.firebase.auth.currentUser.uid, {
          //name: 'James Morrison',
          email: this.props.firebase.auth.currentUser.email,
          // Add your own custom user variables here, ie:
          //subscriptionType: 'pro'
        });
      }
      return <ErrorBoundary>{this.ApplicationView()}</ErrorBoundary>;
    }
  };

  ApplicationView = () => (
    <Router>
      <Suspense fallback={this.LoadingScreen()}>
        <Header/>
        <Container>
          <Switch>
            {/* <Route exact path={ROUTES.HOMEPAGE} component={Homepage} /> */}
            <Route exact path={ROUTES.HOMEPAGE} component={props => <Homepage {...props} />} />
            <Route path={ROUTES.EVENT_CREATE} component={props => <EventCreate {...props} />} />
            <Route path={ROUTES.EVENTS} component={props => <Events {...props} />} />
            <Route path={ROUTES.FORGOT_PASSWORD} component={props => <AccountForgotPW {...props} />} />
            <Route path={ROUTES.CHANGE_PASSWORD} component={props => <AccountChangePW {...props} />} />
            <Route path={ROUTES.RESET_PASSWORD} component={props => <AccountResetPW {...props} />} />
            <Route path={ROUTES.ACCOUNT_PROFILE} component={props => <AccountProfile {...props} />} />
            <Route path={ROUTES.SIGN_IN} component={props => <AccountSignin {...props} />} />
            <Route path={ROUTES.SIGN_UP} component={props => <AccountRegistration {...props} />} />
            <Route path={ROUTES.DASHBOARD} component={props => <AccountDashboard {...props} />} />
            <Route path={ROUTES.UNAUTHORIZED} component={props => <ErrorUnauthorized {...props} />} />
            <Route path={ROUTES.ACCOUNT_ACTION} component={props => <AccountAction {...props} />} />
            <Route component={props => <Error404 {...props} />} />
            {/* <Route path={ROUTES.SIGN_IN} component={SignIn} /> */}
          </Switch>
        </Container>
      </Suspense>
    </Router>
  );

  render() {
    return <div id="App">{this.props.authLoading ? this.LoadingScreen() : this.CheckEnvironment()}</div>;
  }
}

export default withAuthUser(App);
