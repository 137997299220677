import React, { PureComponent } from 'react';
import { Button, Menu, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
/* --------------------------------------------------------------- */
import * as ROUTES from "../../constants/routes.js";

class GuestMenu extends PureComponent {
  render() {
	return (
		<Menu.Menu position='right' className='right-side desktop-nav-menu'>
            <Menu.Item>
                <Button primary as={Link} to={ROUTES.SIGN_UP}><Icon name="signup"/>Register</Button>
            </Menu.Item>

            <Menu.Item>
                <Button basic color='red' as={Link} to={ROUTES.SIGN_IN}><Icon name="sign-in"/>Sign-in</Button>
            </Menu.Item>
        </Menu.Menu>
	)
  }
}
export default GuestMenu;