import React, { Component } from "react";
import { List, Icon } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
/* --------------------------------------------------------------- */
import * as ROUTES from "../../constants/routes.js";

class OffCanvasGuestMenu extends Component {
  render() {
    return (
      <List size={"large"}>
        <List.Item as={Link} to={ROUTES.EVENTS} className="hyperlink white" onClick={this.props.toggleOffCanvas}>
          <List.Content>
            <Icon name="list" color={"grey"} fitted/>
            Events
          </List.Content>
        </List.Item>
        <List.Item as={Link} to={ROUTES.SIGN_UP} className="hyperlink white" onClick={this.props.toggleOffCanvas}>
          <List.Content>
            <Icon name="signup" color={"grey"} fitted />
            Register
          </List.Content>
        </List.Item>
        <List.Item as={Link} to={ROUTES.SIGN_IN} className="hyperlink white" onClick={this.props.toggleOffCanvas}>
          <List.Content>
            <Icon name="sign-in" color={"grey"} fitted />
            Sign-in
          </List.Content>
        </List.Item>
      </List>
    );
  }
}

export default withRouter(OffCanvasGuestMenu);
