import React, { Component } from "react";
import { Button, Form, Grid, Input, Message, Icon } from "semantic-ui-react";
import { withFirebase, withAuthCondition } from "../../Firebase/index.js";
import SkewHeading from "../../Styling/SkewHeading/SkewHeading.js";

const INITIAL_STATE = {
  firstname: "",
  lastname: "",
  phonenumber: "",
  zipcode: "",
  error: null,
  notice: null,
  fieldErrors: {},
  formErrors: [],
  isLoading: true
};

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    this.fieldprofilefirstname = React.createRef();
    this.fieldprofilelastname = React.createRef();
    this.fieldprofilezipcode = React.createRef();
    this.fieldprofilephonenumber = React.createRef();
  }

  componentDidMount = () => {
    var docRef = this.props.firebase.db.collection("users").doc(this.props.authUser.uid);
    docRef
      .get()
      .then(doc => {
        this.setState({ isLoading: false });
        if (doc.exists) {
          var docData = doc.data();
          if (docData.lastUpdated) {
            this.setState({
              firstname: docData.firstName || "",
              lastname: docData.lastName || "",
              phonenumber: docData.phoneNumber || "",
              zipcode: docData.zipCode || ""
            });
          } else {
            this.setState({
              firstname: docData.firstName || "",
              lastname: docData.lastName || "",
              phonenumber: docData.phoneNumber || "",
              zipcode: docData.zipCode || "",
              error: "Your profile must be completed before you can make purchases and fully utilize this app."
            });
          }
        } else {
          this.setState({
            error: "Your profile must be completed before you can make purchases and fully utilize this app."
          });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        this.handleResponseError(error);
      });
  };

  errorsList = () => {
    if (this.state.formErrors.length) {
      return this.state.formErrors.map((errorItem, index) => {
        return <li key={index}>{errorItem}</li>;
      });
    }
  };

  pageErrors = () => {
    return (
      <Message error size={"tiny"} className="form-errors">
        <Message.Header>Errors</Message.Header>
        <Message.Content>
          <ul>{this.errorsList()}</ul>
        </Message.Content>
      </Message>
    );
  };

  handleResponseError = error => {
    this.setState(prevState => ({
      formErrors: [...prevState.formErrors, error.message]
    }));
  };

  validateForm = () => {
    const firstname = this.fieldprofilefirstname.current.inputRef.value.trim();
    const lastname = this.fieldprofilelastname.current.inputRef.value.trim();
    const zipcode = this.fieldprofilezipcode.current.value.trim().replace(/\D+/g, "");
    const phonenumber = this.fieldprofilephonenumber.current.inputRef.value.trim().replace(/\D+/g, "");

    this.setState(prevState => ({
      formErrors: [],
      fieldErrors: {}
    }));

    let foundErrors = false;

    if (firstname === "" || firstname.length < 2) {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a first name"],
        fieldErrors: { ...prevState.fieldErrors, firstname: true }
      }));
      foundErrors = true;
    }

    if (lastname === "" || lastname.length < 2) {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a last name"],
        fieldErrors: { ...prevState.fieldErrors, lastname: true }
      }));
      foundErrors = true;
    }

    if (zipcode === "" || zipcode.length !== 5) {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a five digit zip code"],
        fieldErrors: { ...prevState.fieldErrors, zipcode: true }
      }));
      foundErrors = true;
    }

    if (phonenumber !== "" && phonenumber.length > 11) {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "If you supply a phone number, it must be ten (10) digits with no dashes"],
        fieldErrors: { ...prevState.fieldErrors, phonenumber: true }
      }));
      foundErrors = true;
    }

    if (foundErrors === true) {
      return false;
    }

    const profileObject = {
      firstName: firstname,
      lastName: lastname,
      phoneNumber: phonenumber,
      zipCode: zipcode
    };

    this.submitForm(profileObject);
  };

  submitForm = profileObj => {
    this.setState({ isLoading: true, error: null, notice: null });
    var updateProfile = this.props.firebase.functions.httpsCallable("updateUserProfile");
    updateProfile(profileObj)
      .then(response => {
        this.setState({
          isLoading: false,
          notice: "User profile updated successfully!"
        });
        this.fieldprofilefirstname.current.inputRef.value = profileObj.firstName;
        this.fieldprofilelastname.current.inputRef.value = profileObj.lastName;
        this.fieldprofilezipcode.current.value = profileObj.zipCode;
        this.fieldprofilephonenumber.current.inputRef.value = profileObj.phoneNumber;
      })
      .catch(error => {
        this.setState({ isLoading: false });
        this.handleResponseError(error);
      });
  };

  render() {
    const errorStyle = {
      fontWeight: "bold",
      color: "#a51a28"
    };
    const noticeStyle = {
      fontWeight: "bold",
      color: "#28721c",
      textAlign: "center"
    };
    return (
      <Grid>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={10} textAlign={"center"}>
            <SkewHeading>Edit Profile</SkewHeading>
            {this.state.error && <p style={errorStyle}>{this.state.error}</p>}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={16} computer={12}>
            <Form
              autoComplete="off"
              loading={this.state.isLoading}
              error={this.state.formErrors.length ? true : false}
              size={"large"}
            >
              <Grid columns={1}>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Form.Field required error={this.state.fieldErrors.firstname ? true : false}>
                    <label>First Name</label>
                    <Input
                      placeholder="First Name"
                      type="text"
                      name="firstname"
                      disabled={this.state.isLoading}
                      defaultValue={this.state.firstname}
                      ref={this.fieldprofilefirstname}
                      maxLength="30"
                    />
                    <small>Required. Used for e-mail communications, support, etc.</small>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Form.Field required error={this.state.fieldErrors.lastname ? true : false}>
                    <label>Last Name</label>
                    <Input
                      placeholder="Last Name"
                      type="text"
                      name="lastname"
                      disabled={this.state.isLoading}
                      defaultValue={this.state.lastname}
                      ref={this.fieldprofilelastname}
                      maxLength="30"
                    />
                    <small>Required. Used for e-mail communications, support, etc.</small>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Form.Field required error={this.state.fieldErrors.zipcode ? true : false}>
                    <label>Zip Code</label>
                    <input
                      placeholder="12345"
                      type="tel"
                      name="zipcode"
                      disabled={this.state.isLoading}
                      defaultValue={this.state.zipcode}
                      ref={this.fieldprofilezipcode}
                      maxLength="5"
                    />
                    <small>Required. Used for usage statistics.</small>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} floated={"right"}>
                  <Form.Field error={this.state.fieldErrors.phonenumber ? true : false}>
                    <label>Phone Number</label>
                    <Input
                      placeholder="2223334444"
                      type="text"
                      name="phonenumber"
                      disabled={this.state.isLoading}
                      defaultValue={this.state.phonenumber}
                      ref={this.fieldprofilephonenumber}
                      maxLength="15"
                    />
                    <small>Optional. Used for support if you need assistance.</small>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {this.state.notice && <p style={noticeStyle}>{this.state.notice}</p>}
                  {this.state.formErrors.length ? this.pageErrors() : null}
                  <Button
                    primary
                    type="submit"
                    size={"large"}
                    floated={"right"}
                    onClick={this.validateForm}
                    disabled={this.state.isLoading}
                  >
                    <Icon name="check"/>Update Profile
                  </Button>
                </Grid.Column>
              </Grid>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const authCondition = authUser => {
  if (!!authUser === false) {
    return { passedCondition: false };
  }
  return { passedCondition: true };
};

export default withFirebase(withAuthCondition(authCondition)(EditProfile));
