import React from "react";

import { withRouter } from "react-router-dom";
import { withFirebase } from "./withFirebase.js";
import * as ROUTES from "../constants/routes.js";
import { Loader, Dimmer } from "semantic-ui-react";

const withAuthCondition = condition => Component => {
  class WithAuthCondition extends React.Component {
    
    _isMounted = false;
    constructor(props) {
      super(props);

      this.state = {
        passedCondition: false
      };
    }
    
    componentDidMount() {
      this._isMounted = true;
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        var previousLocation = this.props.history.location;
        const { passedCondition, optionalDestination } = condition(authUser);
        if(this._isMounted) {
          this.setState({passedCondition: passedCondition});
        }

        if (authUser === null) {
          this.props.history.push({
            pathname: ROUTES.SIGN_IN,
            state: { from: previousLocation, unAuthenticated: true }
          });
        } else if (!passedCondition) {
          if (optionalDestination) {
            var newDestination = null;
            var queryParams = null;
            if (optionalDestination.indexOf("?") > 0) {
              newDestination = optionalDestination.slice(optionalDestination.charAt(0), optionalDestination.indexOf("?"));
              queryParams = optionalDestination.slice(optionalDestination.indexOf("?"), optionalDestination.length);
            }
            if (queryParams !== null) {
              this.props.history.push({
                pathname: newDestination,
                search: queryParams,
                state: { from: previousLocation, shouldRedirect: true }
              });
            } else {
              this.props.history.push({
                pathname: optionalDestination,
                state: { from: previousLocation, shouldRedirect: true }
              });
            }
          } else {
            this.props.history.push({
              pathname: ROUTES.UNAUTHORIZED,
              state: { from: previousLocation, unAuthorized: true }
            });
          }
        } else {
        }
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
      this.listener();
    }
    
    LoadingScreen = () => (
    <Dimmer inverted active>
      <Loader size={"huge"}>Loading</Loader>
    </Dimmer>
  );

    render() {
      return this.state.passedCondition ? <Component {...this.props} authUser={this.props.firebase.auth.currentUser} /> : this.LoadingScreen()
    }
  }

  return withRouter(withFirebase(WithAuthCondition));
};

export default withAuthCondition;
