import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
/* --------------------------------------------------------------- */
import * as ROUTES from "../constants/routes.js";

class ErrorUnauthorized extends Component {
  render() {
    return (
      <Grid container>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={16} computer={16} textAlign={"center"}>
            <h2>Unauthorized Access</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={16} computer={8} textAlign={"center"}>
            <h3 style={{ fontWeight: "bold", color: "#a51a28"}}>
              The action you are trying to perform requires a level of authorization that your account does not possess.
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={16} computer={16} textAlign={"center"}>
            <div>
              <Button size={"large"} basic color="red" as={Link} to={ROUTES.HOMEPAGE}>
                Return Home
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ErrorUnauthorized;
