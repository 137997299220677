import React, { Component } from "react";
import { Button, Form, Grid, Input, Message, Icon } from "semantic-ui-react";
import { withFirebase, withAuthCondition } from "../../Firebase/index.js";
import SkewHeading from "../../Styling/SkewHeading/SkewHeading.js";
//import appConfig from "../../../appConfig.js";

/* --------------------------------------------------------------- */
import * as ROUTES from "../../constants/routes.js";

const INITIAL_STATE = {
  password: "",
  confirmPassword: "",
  formErrors: [],
  fieldErrors: {},
  error: null,
  isLoading: false
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.fieldPassword = React.createRef();
    this.fieldConfirmPassword = React.createRef();
  }

  componentWillMount = () => {
    //document.title = `${appConfig.app.siteTitle} - Change Password`;
  };

  validateForm = () => {
    this.setState({ formErrors: [], fieldErrors: {} });
    const password = this.fieldPassword.current.inputRef.value.trim();
    const confirmPassword = this.fieldConfirmPassword.current.inputRef.value.trim();
    let foundErrors = false;

    if (password === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a new password"],
        fieldErrors: { ...prevState.fieldErrors, password: true }
      }));
      foundErrors = true;
    }
    if (confirmPassword === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must confirm your new password"],
        fieldErrors: { ...prevState.fieldErrors, confirmPassword: true }
      }));
      foundErrors = true;
    }
    if (password !== confirmPassword) {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "Your new passwords must match"],
        fieldErrors: { ...prevState.fieldErrors, confirmPassword: true, password: true }
      }));
      foundErrors = true;
    }

    if (foundErrors === true) {
      return false;
    }

    this.setState({ password, confirmPassword });
    this.submitForm(password);
  };

  handleResponseError = error => {
    this.setState(prevState => ({
      formErrors: [...prevState.formErrors, error.message]
    }));
  };

  submitForm = password => {
    const { history } = this.props;
    //clear out previous error states
    this.setState(prevState => ({
      error: null,
      isLoading: true
    }));

    this.props.firebase
      .changePassword(password)
      .then(success => {
        history.push(`${ROUTES.ACCOUNT_ACTION}?result=passwordWasUpdated`);
      })
      .catch(error => {
        /* REWORK THIS SO THAT OTHER ERRORS CAN BE SHOWN */
        this.setState(prevState => ({
          isLoading: false
        }));
        switch (error.code) {
          case "auth/requires-recent-login": {
            this.setState({ error: error });
            break;
          }
          default: {
            this.handleResponseError(error);
          }
        }
      });
  };

  changePWForm = () => {
    return (
      <Grid.Row centered>
        <Grid.Column mobile={16} tablet={10} computer={6}>
          <Form
            autoComplete="off"
            onSubmit={this.validateForm}
            loading={this.state.isLoading}
            size={"large"}
            error={this.state.formErrors.length ? true : false}
          >
            <Form.Field error={this.state.fieldErrors.password ? true : false}>
              <label>New Password</label>
              <Input
                placeholder="New Password"
                type="password"
                name="password"
                disabled={this.state.isLoading}
                defaultValue={this.state.newpassword}
                ref={this.fieldPassword}
                maxLength="50"
              />
            </Form.Field>
            <Form.Field error={this.state.fieldErrors.confirmPassword ? true : false}>
              <label>Confirm New Password</label>
              <Input
                placeholder="Confirm New Password"
                type="password"
                name="confirmPassword"
                disabled={this.state.isLoading}
                defaultValue={this.state.confirmPassword}
                ref={this.fieldConfirmPassword}
                maxLength="50"
              />
            </Form.Field>
            {this.state.formErrors.length ? this.pageErrors() : null}
            <Button primary type="submit" size={"large"} floated={"right"} disabled={this.state.isLoading}>
            <Icon name="check"/>Change Password
            </Button>
          </Form>
        </Grid.Column>
      </Grid.Row>
    );
  };

  reauthForm = () => {
    return (
      <>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={12} computer={10} textAlign={"center"}>
            <p>
              Your account's last sign-in time does not meet the security threshold. You must logout and re-authenticate with your
              current password before you can change it.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={6} textAlign={"center"}>
            <Form size={"large"} loading={this.state.isLoading}>
              <Button primary type="submit" size={"large"} onClick={this.logOutAndReauth}>
              <Icon name="check"/>Reauthenticate &amp; Continue
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  logOutAndReauth = () => {
    this.props.firebase
      .signoutUser()
      .then(() => {
        const { history } = this.props;
        history.push(ROUTES.CHANGE_PASSWORD);
      })
      .catch(haserror => {
        this.setState({ isLoading: false });
        this.setState({ error: haserror });
      });
  };

  errorsList = () => {
    if (this.state.formErrors.length) {
      return this.state.formErrors.map((errorItem, index) => {
        return <li key={index}>{errorItem}</li>;
      });
    }
  };

  pageErrors = () => {
    return (
      <Message error size={"tiny"} className="form-errors">
        <Message.Header>Errors</Message.Header>
        <Message.Content><ul>{this.errorsList()}</ul></Message.Content>
      </Message>
    );
  };

  render() {
    return (
      <Grid>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={8} textAlign={"center"}>
            <SkewHeading>Change Your Password</SkewHeading>
          </Grid.Column>
        </Grid.Row>
        {this.state.error && this.state.error.code === "auth/requires-recent-login" ? this.reauthForm() : this.changePWForm()}
      </Grid>
    );
  }
}

const authCondition = authUser => {
  if(!!authUser === false) {
    return {passedCondition: false}
  }

  return {passedCondition: true}
}

export default withFirebase(withAuthCondition(authCondition)(ChangePassword));
