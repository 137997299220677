import React, { PureComponent } from 'react';
import './SkewHeading.css';

class SkewHeading extends PureComponent {
  render() {
    return (
        <h2 className="emphasis-heading" style={this.props.style}><span className="emphasis-wrapper"><span className="emphasis">{this.props.children}</span></span></h2>
    );
  }
}

export default SkewHeading;