import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Form, Grid, Input, Message, Icon } from "semantic-ui-react";
import { withFirebase } from "../../Firebase";
import SkewHeading from "../../Styling/SkewHeading/SkewHeading.js";
/* --------------------------------------------------------------- */
import * as ROUTES from "../../constants/routes.js";

const INITIAL_STATE = {
  email: "",
  password: "",
  formErrors: [],
  fieldErrors: {},
  isLoading: false
};

class AccountSignin extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.fieldEmail = React.createRef();
    this.fieldPassword = React.createRef();
  }

  componentWillMount = () => {
    document.title = `Account Sign-in`;
  };

  validateForm = () => {
    this.setState({ formErrors: [], fieldErrors: {} });
    const email = this.fieldEmail.current.inputRef.value.trim();
    const password = this.fieldPassword.current.inputRef.value.trim();
    let foundErrors = false;

    if (email === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter an e-mail address"],
        fieldErrors: { ...prevState.fieldErrors, email: true }
      }));
      foundErrors = true;
    }
    if (password === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a password"],
        fieldErrors: { ...prevState.fieldErrors, password: true }
      }));
      foundErrors = true;
    }

    if (foundErrors === true) {
      return false;
    }

    this.setState({ email, password });
    this.submitForm(email, password);
  };

  handleResponseError = error => {
    if (error.code === "auth/user-not-found" || error.code === "auth/invalid-email" || error.code === "auth/user-disabled") {
      this.setState(prevState => ({
        fieldErrors: { ...prevState.fieldErrors, email: true }
      }));
    }
    if (error.code === "auth/wrong-password") {
      this.setState(prevState => ({
        fieldErrors: { ...prevState.password, password: true }
      }));
    }
    this.setState(prevState => ({
      formErrors: [...prevState.formErrors, error.message]
    }));
  };

  submitForm = (email, password) => {
    let loginDestination = "/dashboard";
    //if we hit a protected route and the user is not logged in, redirect them upon login
    if (this.props.location.state) {
      loginDestination = this.props.location.state.from;
    }
    const { history } = this.props;
    this.setState({ isLoading: true });
    this.props.firebase
      .signinUser(email, password)
      .then(authUser => {
        if (authUser.user.emailVerified) {
          history.push(loginDestination);
        } else {
          history.push(ROUTES.PENDING_CONFIRMATION_DOUBLECHECK);
        }
      })
      .catch(error => {
        this.handleResponseError(error);
        this.setState({
          isLoading: false
        });
      });
  };

  errorsList = () => {
    if (this.state.formErrors.length) {
      return this.state.formErrors.map((errorItem, index) => {
        return <li key={index}>{errorItem}</li>;
      });
    }
  };

  pageErrors = () => {
    return (
      <Message error size={"tiny"} className="form-errors">
        <Message.Header>Errors</Message.Header>
        <Message.Content>
          <ul>{this.errorsList()}</ul>
        </Message.Content>
      </Message>
    );
  };

  render() {
    return (
      <Grid>
        {this.props.location.state && this.props.location.state.unAuthenticated ? (
          <Grid.Row centered>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <h3 style={{ fontWeight: "bold", color: "#a51a28", textAlign: "center" }}>
                This action requires you to be signed-in.
              </h3>
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={8} textAlign={"center"}>
            <SkewHeading>Account Sign-in</SkewHeading>
            <p>
              Don't have an account?{" "}
              <Link className="hyperlink" to={ROUTES.SIGN_UP}>
                Create one here
              </Link>
              .
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={6}>
            <Form
              autoComplete="off"
              onSubmit={this.validateForm}
              loading={this.state.isLoading}
              size={"large"}
              error={this.state.formErrors.length ? true : false}
            >
              <Form.Field error={this.state.fieldErrors.email ? true : false}>
                <label>E-mail</label>
                <Input
                  placeholder="E-mail"
                  type="text"
                  name="email"
                  disabled={this.state.isLoading}
                  defaultValue={this.state.email}
                  ref={this.fieldEmail}
                  maxLength="254"
                />
              </Form.Field>
              <Form.Field error={this.state.fieldErrors.password ? true : false}>
                <label>Password</label>
                <Input
                  placeholder="Password"
                  type="password"
                  name="password"
                  disabled={this.state.isLoading}
                  defaultValue={this.state.password}
                  ref={this.fieldPassword}
                  maxLength="50"
                />
                <Link className="hyperlink underfield" to={ROUTES.FORGOT_PASSWORD}>
                  Forgot your password?
                </Link>
              </Form.Field>
              {this.state.formErrors.length ? this.pageErrors() : null}
              <Button primary type="submit" size={"large"} floated={"right"} disabled={this.state.isLoading}>
                <Icon name="sign-in" />
                Sign-in
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withRouter(withFirebase(AccountSignin));
