import React, { PureComponent } from "react";
import { List, Icon } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { withFirebase } from "../../Firebase/index.js";
/* --------------------------------------------------------------- */
import * as ROUTES from "../../constants/routes.js";

class OffCanvasAuthMenu extends PureComponent {
  logOutUser = () => {
    this.props.firebase
      .signoutUser()
      .then(() => {
        const { history } = this.props;
        //redirect to homepage
        history.push(ROUTES.HOMEPAGE);
      })
      .catch(haserror => {
        this.setState({ isLoading: false });
        this.setState({ error: haserror });
      });
  };

  logOutClick = () => {
    this.props.toggleOffCanvas();
    this.logOutUser();
  };

  render() {
    return (
      <List size={"large"}>
        <List.Item as={Link} to={ROUTES.EVENTS} className="hyperlink white" onClick={this.props.toggleOffCanvas}>
          <List.Content><Icon name="list" color={"grey"} />&nbsp;Events</List.Content>
        </List.Item>
        <List.Item as={Link} to={ROUTES.DASHBOARD} className="hyperlink white" onClick={this.props.toggleOffCanvas}>
          <List.Content><Icon name="dashboard" color={"grey"} />Dashboard</List.Content>
        </List.Item>
        <List.Item as={Link} to={ROUTES.ACCOUNT_PROFILE} className="hyperlink white" onClick={this.props.toggleOffCanvas}>
          <List.Content><Icon name="user" color={"grey"} />Update Profile</List.Content>
        </List.Item>
        <List.Item as={Link} to={ROUTES.CHANGE_PASSWORD} className="hyperlink white" onClick={this.props.toggleOffCanvas}>
          <List.Content><Icon name="lock" color={"grey"} />Change Password</List.Content>
        </List.Item>
        <List.Item as={Link} to={ROUTES.SIGN_IN} className="hyperlink white" onClick={this.logOutClick}>
          <List.Content><Icon name="sign-out" color={"grey"} />Logout</List.Content>
        </List.Item>
      </List>
    );
  }
}

export default withRouter(withFirebase(OffCanvasAuthMenu));
