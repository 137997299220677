import React, { Component } from "react";
import { slide as CanvasMenu } from "react-burger-menu";
import { Image } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { withAuthUser } from "../../Firebase/index.js";
import OffCanvasAuthorizedMenu from './OffCanvasAuthMenu';
import OffCanvasGuestMenu from './OffCanvasGuestMenu';
import './OffCanvasMenu.css';
import * as ROUTES from "../../constants/routes.js";

class OffCanvasNav extends Component {
  render() {
    return (
      <CanvasMenu
        isOpen={this.props.isOpen}
        customBurgerIcon={false}
        onStateChange={this.props.onStateChange}
        width={'75%'}
      >
        <Image className="offcanvas-logo" onClick={this.props.toggleOffCanvas} as={Link} to={ROUTES.HOMEPAGE} centered src="/logos/Pointbox_Navbar_120h_white.png" />
        {this.props.authUser ? <OffCanvasAuthorizedMenu toggleOffCanvas={this.props.toggleOffCanvas}/> : <OffCanvasGuestMenu toggleOffCanvas={this.props.toggleOffCanvas}/> }
        <small style={{color: "hsla(0, 0%, 100%, 0.27)", paddingTop: "10px", textAlign: "right"}}>v1.5</small>
      </CanvasMenu>
    );
  }
}

export default withAuthUser(OffCanvasNav);
