import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu, Container, Image, Icon } from "semantic-ui-react";
import AuthUserMenu from "./AuthUserMenu.js";
import GuestMenu from "./GuestMenu.js";
import { withAuthUser } from "../../Firebase";
import OffCanvasButton from "./OffCanvasButton.js";
import "./Navbar.scss";
/* --------------------------------------------------------------- */
import * as ROUTES from "../../constants/routes.js";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { activeItem: "home" };
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <div className="navbar-container">
        <Container>
          <Menu size="large" secondary stackable={false}>
            <Menu.Item name="logo" className="navbar-logo" onClick={this.handleItemClick} as={Link} to={ROUTES.HOMEPAGE}>
              <Image height="60" src="/logos/Pointbox_Navbar_120h.png" />
            </Menu.Item>

            <Menu.Item
              name="events"
              className="navbar-link desktop-nav-menu"
              active={activeItem === "events"}
              onClick={this.handleItemClick}
              as={Link}
              to={ROUTES.EVENTS}
            >
              <Icon name="list" color="red" />
              Events
            </Menu.Item>
            {this.props.authUser ? <AuthUserMenu /> : <GuestMenu />}
            <OffCanvasButton toggleOffCanvas={this.props.toggleOffCanvas} />
          </Menu>
        </Container>
      </div>
    );
  }
}

export default withAuthUser(Navbar);
