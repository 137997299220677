import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form, Grid, Input, Message, Icon } from "semantic-ui-react";
import { withFirebase } from "../../Firebase/index.js";
import * as ROUTES from "../../constants/routes.js";
import SkewHeading from "../../Styling/SkewHeading/SkewHeading.js";

const INITIAL_STATE = {
  email: "",
  error: null,
  isLoading: false,
  formErrors: [],
  fieldErrors: {}
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.fieldEmail = React.createRef();
  }

  errorsList = () => {
    if (this.state.formErrors.length) {
      return this.state.formErrors.map((errorItem, index) => {
        return <li key={index}>{errorItem}</li>;
      });
    }
  };

  pageErrors = () => {
    return (
      <Message error size={"tiny"} className="form-errors">
        <Message.Header>Errors</Message.Header>
        <Message.Content><ul>{this.errorsList()}</ul></Message.Content>
      </Message>
    );
  };

  handleResponseError = error => {
    if (error.code === "auth/user-not-found" || error.code === "auth/invalid-email" || error.code === "auth/user-disabled") {
      this.setState(prevState => ({
        fieldErrors: { ...prevState.fieldErrors, email: true }
      }));
    }

    this.setState(prevState => ({
      formErrors: [...prevState.formErrors, error.message]
    }));
  };

  validateForm = () => {
    this.setState({ formErrors: [], fieldErrors: {} });
    const emailAddress = this.fieldEmail.current.inputRef.value.trim();
    let foundErrors = false;

    if (emailAddress === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter your account's e-mail address"],
        fieldErrors: { ...prevState.fieldErrors, email: true }
      }));
      foundErrors = true;
    }

    if (foundErrors === true) {
      return false;
    }
    this.submitForm(emailAddress);
  };

  submitForm = email => {
    const { history } = this.props;
    //clear out previous error states
    this.setState(prevState => ({
      error: null,
      isLoading: true
    }));

    this.props.firebase
      .sendPasswordResetEmail(email)
      .then(success => {
        this.setState(() => ({ ...INITIAL_STATE }));
        history.push(`${ROUTES.ACCOUNT_ACTION}?result=passwordResetEmailSent`);
      })
      .catch(error => {
        this.handleResponseError(error);
        this.setState({
          isLoading: false
        });
      });
  };

  render() {
    return (
      <Grid>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={8} textAlign={"center"}>
            <SkewHeading>Reset Your Password</SkewHeading>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={6}>
            <Form
              autoComplete="off"
              onSubmit={this.validateForm}
              loading={this.state.isLoading}
              size={"large"}
              error={this.state.formErrors.length ? true : false}
            >
              <Form.Field error={this.state.fieldErrors.email ? true : false}>
                <label>E-mail Address</label>
                <Input
                  placeholder="E-mail"
                  type="text"
                  name="email"
                  disabled={this.state.isLoading}
                  defaultValue={this.state.email}
                  ref={this.fieldEmail}
                  maxLength="50"
                />
              </Form.Field>
              {this.state.formErrors.length ? this.pageErrors() : null}
              <Button primary type="submit" size={"large"} floated={"right"} disabled={this.state.isLoading}>
              <Icon name="send"/>Send Reset E-mail
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withRouter(withFirebase(ForgotPassword));
