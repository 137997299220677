import app from "firebase/app";
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/functions';

const productionConfig = {
  apiKey: "AIzaSyCzyuWojo5sRA4ESmB58otKpP5Y2T50Lck",
  authDomain: "pointbox-213b2.firebaseapp.com",
  databaseURL: "https://pointbox-213b2.firebaseio.com",
  projectId: "pointbox-213b2",
  storageBucket: "pointbox-213b2.appspot.com",
  messagingSenderId: "1001372267871"
};

const developmentConfig = {
  apiKey: "AIzaSyCzyuWojo5sRA4ESmB58otKpP5Y2T50Lck",
  authDomain: "pointbox-213b2.firebaseapp.com",
  databaseURL: "https://pointbox-213b2.firebaseio.com",
  projectId: "pointbox-213b2",
  storageBucket: "pointbox-213b2.appspot.com",
  messagingSenderId: "1001372267871"
};

const config = process.env.NODE_ENV === "production" ? productionConfig : developmentConfig;

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.dbMethods = app.firestore;
    this.db.settings({timestampsInSnapshots: true});
  }

  createUser = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  };
  sendVerificationEmail = () => {
    return this.auth.currentUser.sendEmailVerification();
  };
  checkActionCode = code => {
    return this.auth.checkActionCode(code);
  };
  applyVerificationCode = code => {
    return this.auth.applyActionCode(code);
  };
  signinUser = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  };
  signoutUser = () => {
    return this.auth.signOut();
  };
  sendPasswordResetEmail = email => {
    return this.auth.sendPasswordResetEmail(email);
  };
  checkPWResetCode = code => {
    return this.auth.verifyPasswordResetCode(code);
  };
  resetPassword = (code, newpassword) => {
    return this.auth.confirmPasswordReset(code, newpassword);
  };
  changePassword = password => {
    return this.auth.currentUser.updatePassword(password);
  };
  reAuthenticate = () => {
    return this.auth.currentUser.reauthenticateAndRetrieveDataWithCredential();
  };
}

export default Firebase;
