import React from 'react';

import AuthUserContext from './contextAuth.js';
import { withFirebase } from './withFirebase.js';

const withAuthUser = Component => {
  class WithAuthUser extends React.Component {
    _isMounted = false;
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        authLoading: true
      };
    }

    componentDidMount() {
        this._isMounted = true;
        this.listener = this.props.firebase.auth.onAuthStateChanged(
          authUser => {
            if(authUser) {
              if(this._isMounted) {
                this.setState({ authUser: authUser, authLoading: false })
              }
            } else {
              if(this._isMounted) {
                this.setState({ authUser: null, authLoading: false });
              }
            }
          }
        );
    }

    componentWillUnmount() {
      this._isMounted = false;
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} authUser={this.state.authUser} authLoading={this.state.authLoading} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthUser);
};

export default withAuthUser;