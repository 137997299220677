import React, { PureComponent } from 'react';
import { Menu, Dropdown, Icon } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import {withFirebase} from "../../Firebase/index.js";
/* --------------------------------------------------------------- */
import * as ROUTES from "../../constants/routes.js";

class AuthorizedUserMenu extends PureComponent {

    logOutUser = () => {
        this.props.firebase.signoutUser().then(() => {
            const {history} = this.props;
            //redirect to homepage
            history.push(ROUTES.HOMEPAGE);
        }).catch(haserror => {
          console.error(haserror);
        });
      }

  render() {
	return (
		<Menu.Menu position='right' className='right-side desktop-nav-menu'>
            <Menu.Item name='home' className="navbar-link" as={Link} to={ROUTES.DASHBOARD} ><Icon name='cube' color='red' /> Dashboard</Menu.Item>
            <Dropdown item trigger={<span><Icon name='user circle' color='red'/> My Account</span>}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={ROUTES.ACCOUNT_PROFILE}>Edit Profile</Dropdown.Item>
              <Dropdown.Item as={Link} to={ROUTES.CHANGE_PASSWORD}>Change Password</Dropdown.Item>
              <Dropdown.Item onClick={this.logOutUser}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
	)
  }
}

export default withRouter(withFirebase(AuthorizedUserMenu));