import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form, Grid, Input, Message, Icon } from "semantic-ui-react";
import { withFirebase } from "../../Firebase/index.js";
import * as queryString from "qs";
import SkewHeading from "../../Styling/SkewHeading/SkewHeading.js";
/* --------------------------------------------------------------- */
import * as ROUTES from "../../constants/routes.js";

const INITIAL_STATE = {
  password: "",
  confirmPassword: "",
  formErrors: [],
  fieldErrors: {},
  isLoading: false,
  resetCode: null
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.fieldPassword = React.createRef();
    this.fieldConfirmPassword = React.createRef();
  }

  componentDidMount() {
    this.saveQueryStringCode();
  }

  errorsList = () => {
    if (this.state.formErrors.length) {
      return this.state.formErrors.map((errorItem, index) => {
        return <li key={index}>{errorItem}</li>;
      });
    }
  };

  pageErrors = () => {
    return (
      <Message error size={"tiny"} className="form-errors">
        <Message.Header>Errors</Message.Header>
        <Message.Content>
          <ul>{this.errorsList()}</ul>
        </Message.Content>
      </Message>
    );
  };

  saveQueryStringCode = () => {
    const queryValues = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    var resetCode = queryValues.code;
    this.setState({ resetCode: resetCode });
  };

  validateForm = () => {
    this.setState({ formErrors: [], fieldErrors: {} });
    const password = this.fieldPassword.current.inputRef.value.trim();
    const confirmPassword = this.fieldConfirmPassword.current.inputRef.value.trim();
    let foundErrors = false;

    if (password === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a new password"],
        fieldErrors: { ...prevState.fieldErrors, password: true }
      }));
      foundErrors = true;
    }
    if (confirmPassword === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must confirm your new password"],
        fieldErrors: { ...prevState.fieldErrors, confirmPassword: true }
      }));
      foundErrors = true;
    }
    if (password !== confirmPassword) {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "Your new passwords must match"],
        fieldErrors: { ...prevState.fieldErrors, confirmPassword: true, password: true }
      }));
      foundErrors = true;
    }

    if (foundErrors === true) {
      return false;
    }

    this.setState({ password, confirmPassword });
    this.submitForm(password);
  };

  submitForm = password => {
    const { history } = this.props;
    //clear out previous error states
    this.setState(prevState => ({
      isLoading: true
    }));

    this.props.firebase
      .resetPassword(this.state.resetCode, password)
      .then(success => {
        history.push(`${ROUTES.ACCOUNT_ACTION}?result=passwordWasReset`);
      })
      .catch(error => {
        this.handleResponseError(error);
        this.setState({
          isLoading: false
        });
      });
  };

  handleResponseError = error => {
    this.setState(prevState => ({
      formErrors: [...prevState.formErrors, error.message]
    }));
  };

  render() {
    return (
      <Grid>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={8} textAlign={"center"}>
            <SkewHeading>Reset Your Password</SkewHeading>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={6}>
            <Form
              autoComplete="off"
              onSubmit={this.validateForm}
              loading={this.state.isLoading}
              size={"large"}
              error={this.state.formErrors.length ? true : false}
            >
              <Form.Field error={this.state.fieldErrors.password ? true : false}>
                <label>New Password</label>
                <Input
                  placeholder="New Password"
                  type="password"
                  name="password"
                  disabled={this.state.isLoading}
                  defaultValue={this.state.newpassword}
                  ref={this.fieldPassword}
                  maxLength="50"
                />
              </Form.Field>
              <Form.Field error={this.state.fieldErrors.confirmPassword ? true : false}>
                <label>Confirm New Password</label>
                <Input
                  placeholder="Confirm New Password"
                  type="password"
                  name="confirmPassword"
                  disabled={this.state.isLoading}
                  defaultValue={this.state.confirmPassword}
                  ref={this.fieldConfirmPassword}
                  maxLength="50"
                />
              </Form.Field>
              {this.state.formErrors.length ? this.pageErrors() : null}
              <Button primary type="submit" size={"large"} floated={"right"} disabled={this.state.isLoading}>
              <Icon name="lock"/>Change Password
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withRouter(withFirebase(ResetPassword));
