export const HOMEPAGE = '/';
export const DASHBOARD = '/dashboard';
export const UNAUTHORIZED = '/unauthorized';
export const SIGN_IN = '/account/sign-in';
export const SIGN_UP = '/account/register';
export const ACCOUNT_ACTION = '/account/action';
export const ACCOUNT_PROFILE = '/account/profile';
export const PENDING_CONFIRMATION = '/account/action?result=pendingVerification';
export const PENDING_CONFIRMATION_DOUBLECHECK = '/account/action?result=pendingVerificationDoubleCheck';
export const FORGOT_PASSWORD = '/account/forgotpassword';
export const CHANGE_PASSWORD = '/account/changepassword';
export const RESET_PASSWORD = '/account/resetpassword';
export const EVENTS = '/events';
export const EVENT_CREATE = '/event/create';
