import React, { Component } from "react";
import Navbar from "./Navbar/Navbar.js";
import OffCanvasMenu from "./OffCanvasNav/OffCanvasNav.js";

const INITIAL_STATE = {
  offCanvasVisible: false
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  toggleOffCanvasMenu = () => {
    this.setState(prevState => ({
      offCanvasVisible: !prevState.offCanvasVisible
    }));
  };

  currentOffCanvasState = stateObj => {
    this.setState({ offCanvasVisible: stateObj.isOpen });
  };

  render() {
    return (
      <>
        <OffCanvasMenu
          isOpen={this.state.offCanvasVisible}
          onStateChange={this.currentOffCanvasState}
          toggleOffCanvas={this.toggleOffCanvasMenu}
        />
        <Navbar toggleOffCanvas={this.toggleOffCanvasMenu} />
      </>
    );
  }
}

export default Header;
