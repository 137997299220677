import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, errorMessage: error.toString() };
    }
  
    componentDidCatch(error, info) {
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, info);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <div style={{textAlign: "center", paddingTop: "25px"}}>
                <img height="60" alt="Pointbox logo" src="/logos/Pointbox_Navbar_120h.png" />
                <h2>Something went wrong.</h2>
                <h3>The developers have been alerted!</h3>
                <pre>{this.state.errorMessage}</pre>
            </div>
        );
      }
      return this.props.children; 
    }
  }

export default ErrorBoundary;