import React, { Component } from "react";
import { Button, Form, Grid, Input, Message, Icon } from "semantic-ui-react";
import { withFirebase } from "../../Firebase";
import SkewHeading from "../../Styling/SkewHeading/SkewHeading.js";
/* --------------------------------------------------------------- */
import * as ROUTES from "../../constants/routes.js";

const INITIAL_STATE = {
  email: "",
  password: "",
  passwordConfirm: "",
  formErrors: [],
  fieldErrors: {},
  isLoading: false
};

class AccountRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    this.fieldEmail = React.createRef();
    this.fieldPassword = React.createRef();
    this.fieldConfirmPassword = React.createRef();
  }

  validateForm = () => {
    this.setState({ formErrors: [], fieldErrors: {} });
    const email = this.fieldEmail.current.inputRef.value.trim();
    const password = this.fieldPassword.current.inputRef.value.trim();
    const passwordConfirm = this.fieldConfirmPassword.current.inputRef.value.trim();

    let foundErrors = false;

    if (email === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter an e-mail address"],
        fieldErrors: { ...prevState.fieldErrors, email: true }
      }));
      foundErrors = true;
    }
    if (password === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a password"],
        fieldErrors: { ...prevState.fieldErrors, password: true }
      }));
      foundErrors = true;
    }
    if (passwordConfirm === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must confirm your password"],
        fieldErrors: { ...prevState.fieldErrors, passwordConfirm: true }
      }));
      foundErrors = true;
    }
    if (password !== passwordConfirm) {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "Your passwords must match"],
        fieldErrors: { ...prevState.fieldErrors, password: true, passwordConfirm: true }
      }));
      foundErrors = true;
    }

    if (foundErrors === true) {
      return false;
    }

    this.setState({ email, password, passwordConfirm });
    this.submitForm(email, password);
  };

  handleResponseError = error => {
    if(error.code === "auth/email-already-in-use" || error.code === "auth/invalid-email") {
      this.setState(prevState => ({
        fieldErrors: { ...prevState.fieldErrors, email: true }
      }));
    }
    if(error.code === "auth/weak-password") {
      this.setState(prevState => ({
        fieldErrors: { ...prevState.password, email: true }
      }));
    }
    this.setState(prevState => ({
      formErrors: [...prevState.formErrors, error.message]
    }));
  };

  submitForm = (email, password) => {
    const { history } = this.props;
    this.setState({ isLoading: true });
    this.props.firebase
      .createUser(email, password)
      .then(authUser => {
        return this.props.firebase.sendVerificationEmail().then(() => {
          history.push(ROUTES.PENDING_CONFIRMATION);
        });
      })
      .catch(error => {
        this.handleResponseError(error);
        this.setState({
          isLoading: false
        });
      });
  };

  errorsList = () => {
    if (this.state.formErrors.length) {
      return this.state.formErrors.map((errorItem, index) => {
        return <li key={index}>{errorItem}</li>;
      });
    }
  };

  pageErrors = () => {
    return (
      <Message error size={"tiny"} className="form-errors">
        <Message.Header>Errors</Message.Header>
        <Message.Content><ul>{this.errorsList()}</ul></Message.Content>
      </Message>
    );
  };

  render() {
    return (
      <Grid>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={8} textAlign={"center"}>
            <SkewHeading>Account Registration</SkewHeading>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={6}>
            <Form
              autoComplete="off"
              onSubmit={this.validateForm}
              loading={this.state.isLoading}
              size={"large"}
              error={this.state.formErrors.length ? true : false}
            >
              <Form.Field error={this.state.fieldErrors.email ? true : false}>
                <label>E-mail</label>
                <Input
                  placeholder="E-mail"
                  type="text"
                  name="email"
                  disabled={this.state.isLoading}
                  defaultValue={this.state.email}
                  ref={this.fieldEmail}
                  maxLength="254"
                />
              </Form.Field>
              <Form.Field error={this.state.fieldErrors.password ? true : false}>
                <label>Password</label>
                <Input
                  placeholder="Password"
                  type="password"
                  name="password"
                  disabled={this.state.isLoading}
                  defaultValue={this.state.password}
                  ref={this.fieldPassword}
                  maxLength="50"
                />
              </Form.Field>
              <Form.Field error={this.state.fieldErrors.passwordConfirm ? true : false}>
                <label>Confirm Password</label>
                <Input
                  placeholder="Confirm Password"
                  type="password"
                  name="passwordConfirm"
                  disabled={this.state.isLoading}
                  defaultValue={this.state.passwordConfirm}
                  ref={this.fieldConfirmPassword}
                  maxLength="50"
                />
              </Form.Field>
              {this.state.formErrors.length ? this.pageErrors() : null}
              <Button primary type="submit" size={"large"} floated={"right"} disabled={this.state.isLoading}>
              <Icon name="signup"/>Register
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withFirebase(AccountRegistration);
