//import appConfig from '../../appConfig.js'
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Grid } from "semantic-ui-react";
/* --------------------------------------------------------------- */
import * as ROUTES from "../constants/routes.js";

class Error404 extends PureComponent {
  render() {
    return (
      <div className="error-404">
        <Grid container>
          <Grid.Row centered>
            <Grid.Column mobile={16} tablet={10} computer={6} textAlign={"center"}>
              <h2>Oops! This is awkward.</h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column mobile={16} tablet={10} computer={6} textAlign={"center"}>
              <p>That page does not exist. Please double-check your links and try again.</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column mobile={16} tablet={10} computer={6} textAlign={"center"}>
              <Form>
                <Button basic size={"large"} color="red" as={Link} to={ROUTES.HOMEPAGE}>
                  Return home
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default Error404;
